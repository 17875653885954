<template>
<div class="dashboard-widget-tabs p-0 dashboard-header-boxed">
    <div class="dashboard-widget-tabs-content mb-0 p-15">
        <h2>
            <!-- <i class="uil uil-list-ol-alt"></i> -->
            <svg class="rightnav-svg rightnav-svg-page" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path class="fa-primary" d="M256 64c0-35.35-28.65-64-64-64S128 28.65 128 64C92.65 64 64 92.65 64 128v16C64 152.8 71.16 160 80 160h224C312.8 160 320 152.8 320 144V128C320 92.65 291.3 64 256 64zM192 88C178.7 88 168 77.25 168 64c0-13.26 10.75-24 24-24S216 50.74 216 64C216 77.25 205.3 88 192 88zM96 376c-13.25 0-24 10.75-24 24S82.75 424 96 424s24-10.75 24-24S109.3 376 96 376zM164.7 228.7L112 281.4L91.31 260.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62l32 32C103.8 318.4 107.9 320 112 320s8.188-1.562 11.31-4.688l64-64c6.25-6.25 6.25-16.38 0-22.62S170.9 222.4 164.7 228.7z" />
                <path class="fa-secondary" d="M336 64H256c35.35 0 64 28.65 64 64v16C320 152.8 312.8 160 304 160h-224C71.16 160 64 152.8 64 144V128c0-35.35 28.65-64 64-64H48C21.49 64 0 85.49 0 112v352C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48v-352C384 85.49 362.5 64 336 64zM96 424c-13.25 0-24-10.75-24-24S82.75 376 96 376s24 10.75 24 24S109.3 424 96 424zM112 320c-4.094 0-8.188-1.562-11.31-4.688l-32-32c-6.25-6.25-6.25-16.38 0-22.62s16.38-6.25 22.62 0L112 281.4l52.69-52.69c6.25-6.25 16.38-6.25 22.62 0s6.25 16.38 0 22.62l-64 64C120.2 318.4 116.1 320 112 320zM304 416h-128C167.2 416 160 408.8 160 400C160 391.2 167.2 384 176 384h128c8.801 0 16 7.199 16 16C320 408.8 312.8 416 304 416zM304 320h-96C199.2 320 192 312.8 192 304C192 295.2 199.2 288 208 288h96C312.8 288 320 295.2 320 304C320 312.8 312.8 320 304 320z" /></svg>
            <span class="page-title-svg">وظایف روزانه</span>
        </h2>
        <div class="dashboard-widget-h-btns">
            <a href="" @click.prevent="showSidebar('list')" class="m-left m-top tgju-btn tgju-btn-primary t-display-inline-block d-w-min-hide hide-pages-mobile-btn" v-tooltip="'فهرست وظایف روزانه'"><i class="uil uil-list-ol-alt"></i> <span class="hide-m-min">فهرست وظایف روزانه</span></a>
            <a v-if="!widgetLoad" href="" @click.prevent="showSidebar('options')" class="m-left m-top tgju-btn tgju-btn-light todo-setting-btn hide-pages-mobile-btn" v-tooltip="'تنظیمات'"><i class="uil uil-setting"></i></a>
            <a href="" @click.prevent="open_help_modal()" class="m-left m-left-0 m-top tgju-btn tgju-btn-light tgju-help-btn" v-tooltip="'راهنما'"><i class="uil uil uil-question-circle"></i></a>
        </div>
    </div>
    <div class="mobile-btns-box show-pages-mobile-btn" style="padding: 0;margin-bottom: 10px;">
        <a href="" @click.prevent="showSidebar('list')" class="tgju-btn tgju-btn-primary" v-tooltip="'فهرست وظایف روزانه'"><i class="uil uil-list-ol-alt"></i> <span>فهرست وظایف روزانه</span></a>
        <a v-if="!widgetLoad" href="" @click.prevent="showSidebar('options')" class="tgju-btn tgju-btn-light todo-setting-btn" v-tooltip="'تنظیمات'"><i class="uil uil-setting"></i></a>
    </div>

    <div class="sidebar-box">
        <slot v-if="widgetLoad">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>
        <slot v-else>
            <div class="content-box fix-content" :class="{'t-display-none': sidebarShow == true}">
                <div class="list-row l-head">
                    <div class="list-col l-item">
                        <span class="uil uil-arrow-right widget-min-back-to-list" @click="makeResponsive(2)"></span>
                    </div>
                    <div class="list-col l-title">
                        <span v-if="activeTodo && activeTodo.title">مشاهده لیست وظایف <strong>{{ activeTodo.title}}</strong></span>
                    </div>
                </div>
                <div v-if="!filteredList.length">
                    <div class="popup-dash-empty">
                        <i aria-hidden="true" class="uil uil-exclamation"></i>
                        <span>فهرستی برای نمایش وجود ندارد</span>
                        <span>لطفا ابتدا یک فهرست ایجاد نمایید</span>
                    </div>
                </div>
                <slot v-else>
                    <div v-if="!todoStarShow" class="tgju-widget-content full-padding">
                        <div class="list-widget-search-container">
                            <i class="fa fa-search list-widget-search-icon" aria-hidden="true"></i>
                            <input class="list-widget-search" type="text" placeholder="جستجو در وظیفه‌ها ..." v-model="todoFilterInput" @keyup="filterTodo">
                        </div>
                        <div class="list-widget list-dash-items list-widget-structure todo-list-checkbox" :class="{ 'checked-show': showChecked }">
                            <draggable v-model="activeTodo.items" group="todosItems" @start="drag=true" @end="saveTodoItem()" ghost-class="drag-ghost" v-if="activeTodo">
                                <template v-for="todoItem in activeTodo.items">
                                    <div v-if="!todoItem.hide" :key="todoItem.id" class="list-row item-draggable hover bold" :class="{'checked': todoItem.checked}">
                                        <div class="list-col l-item pointer no-border">
                                            <input class="option-input checkbox todo-check" @click="checkTodoItem(todoItem.id)" type="checkbox" :checked="todoItem.checked">
                                        </div>
                                        <div class="list-col l-title l-title-widget-structure show-widget-info" @click="triggerTodoPanel(todoItem.id); makeResponsive(3);">
                                            <span class="pr-0 checkbox-title">{{ todoItem.title }}</span>
                                        </div>
                                        <div class="list-col l-item pointer">
                                            <i class="fa" v-tooltip="'منتخب‌ها'" :class="{'fa-star active-star': todoItem.star, 'fa-star-o': !todoItem.star}" @click="starTodoItem(todoItem.id)"></i>
                                        </div>
                                        <div class="list-col l-item pointer show-widget-info" @click="triggerTodoPanel(todoItem.id)">
                                            <i class="uil uil-edit-alt" v-tooltip="'ویرایش'"></i>
                                        </div>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                        <div :class="'list-widget-add-container ' + (addEditTodoLoading ? 'loading-active' : '')">
                            <i class="fa fa-plus list-widget-add-icon-todo" v-tooltip="'افزودن وظیفه جدید'" aria-hidden="true" @click="addTodoItem()"></i>
                            <input class="list-widget-todo-add" type="text" placeholder="افزودن وظیفه جدید" v-model="newTodoItem" @keyup.enter="addTodoItem()">
                        </div>
                    </div>
                    <div v-else class="tgju-widget-content full-padding">
                        <div class="list-widget-search-container">
                            <i class="fa fa-search list-widget-search-icon" aria-hidden="true"></i>
                            <input class="list-widget-search" type="text" placeholder="جستجو در وظیفه‌ها ..." v-model="todoFilterInput" @keyup="filterTodo">
                        </div>
                        <div class="list-widget list-dash-items list-widget-structure todo-list-checkbox" :class="{ 'checked-show': showChecked }">
                            <template v-for="todoItem in todoStarList">
                                <div v-if="!todoItem.hide" :key="todoItem.id" class="list-row item-draggable hover bold" :class="{'checked': todoItem.checked}">
                                    <div class="list-col l-item pointer no-border">
                                        <input class="option-input checkbox todo-check" @click="checkTodoItem(todoItem.id)" type="checkbox" :checked="todoItem.checked">
                                    </div>
                                    <div class="list-col l-title l-title-widget-structure show-widget-info" @click="triggerTodoPanel(todoItem.id); makeResponsive(3);">
                                        <span class="pr-0 checkbox-title">{{ todoItem.title }}</span>
                                    </div>
                                    <div class="list-col l-item pointer">
                                        <i class="fa" v-tooltip="'منتخب‌ها'" :class="{'fa-star active-star': todoItem.star, 'fa-star-o': !todoItem.star}" @click="starTodoItem(todoItem.id)"></i>
                                    </div>
                                    <div class="list-col l-item pointer show-widget-info" @click="triggerTodoPanel(todoItem.id)">
                                        <i class="uil uil-edit-alt" v-tooltip="'ویرایش'"></i>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div v-if="!todoStarShow" :class="'list-widget-add-container ' + (addEditTodoLoading ? 'loading-active' : '')">
                            <i class="fa fa-plus list-widget-add-icon-todo" v-tooltip="'افزودن وظیفه جدید'" aria-hidden="true" @click="addTodoItem()"></i>
                            <input class="list-widget-todo-add" type="text" placeholder="افزودن وظیفه جدید" v-model="newTodoItem" @keyup.enter="addTodoItem()">
                        </div>
                    </div>
                </slot>
            </div>
            <div class="c-sidebar fix-sidebar" :class="{'t-display-none': sidebarShow == false}">
                <div class="sidebar-list">
                    <div class="sidebar-title-row t-display-block-flex">
                        <h3><i class="uil uil-list-ol-alt"></i> فهرست وظایف روزانه</h3>
                        <i class="uil uil-times pointer" @click.prevent="hideSidebar()"></i>
                    </div>
                    <input class="widget-input m-top w-100" type="text" placeholder="جستوجو در فهرست وظایف" v-model="searchQuery">
                    <div class="list-widget list-dash-items list-widget-structure">
                        <template>
                            <div :class="'list-row item-draggable hover bold  ' + (!activeTodo ? 'active-item' : '')">
                                <div class="list-col l-item pointer" v-tooltip="'منتخب‌ها'">
                                    <i class="uil uil-star"></i>
                                </div>
                                <div class="list-col l-title l-title-widget-structure" @click="showStarsTodo()">
                                    <span>منتخب‌ها</span>
                                </div>
                                <div class="list-col l-item list-col-inactive">
                                    <i class="uil uil-edit-alt"></i>
                                </div>
                                <div class="list-col l-item red-color list-col-inactive">
                                    <i class="uil uil-trash remove-row"></i>
                                </div>
                            </div>
                        </template>
                        <draggable v-model="todos" group="todos" @start="drag=true" @end="saveTodosSort()" ghost-class="drag-ghost" handle=".list-drag">
                            <template v-for="todo in filteredList">
                                <div v-if="!todo.hide" :key="todo.id" :class="'list-row item-draggable hover bold  ' + (activeTodo.id  == todo.id? 'active-item' : '')">
                                    <div class="list-col l-item pointer">
                                        <i class="uil uil-arrows-v list-drag" v-tooltip="'جابجایی'"></i>
                                    </div>
                                    <div class="list-col l-title l-title-widget-structure" @click="openTodo(todo.id); makeResponsive(1);">
                                        <span>{{ todo.title }}</span>
                                    </div>
                                    <div class="list-col l-item pointer" @click="open_todo_edit_modal(todo.id)">
                                        <i class="uil uil-edit-alt" v-tooltip="'ویرایش'"></i>
                                    </div>
                                    <div class="list-col l-item red-color pointer" @click="removeTodo(todo.id)">
                                        <i class="uil uil-trash remove-row" v-tooltip="'حذف'"></i>
                                    </div>
                                </div>
                            </template>
                        </draggable>
                        <div :class="'list-widget-add-container border-top-1 border-0 ' + (addEditLoading ? 'loading-active' : '')">
                            <i class="fa fa-plus list-widget-add-icon" v-tooltip="'افزودن فهرست جدید'" aria-hidden="true" @click="addTodo()"></i>
                            <input class="list-widget-input-add" type="text" placeholder="افزودن فهرست جدید" v-model="newTodo" @keyup.enter="addTodo()">
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="sidebarOptions == 'options' || sidebarOptions == 'edit'" class="c-sidebar c-sidebar-left" :class="{'t-display-none': sidebarShow == false}">
                <div v-if="sidebarOptions == 'edit'" class="sidebar-edit">
                    <div class="sidebar-title-row">
                        <h3><i class="uil uil-edit-alt"></i> ویرایش </h3>
                        <i class="uil uil-trash m-left pointer" @click.prevent="removeTodoItem(activeTodoItem.id)"></i>
                        <i class="uil uil-times pointer" @click.prevent="showSidebar2()"></i>
                    </div>
                    <div class="tgju-widget-content full-padding">
                        <div class="list-widget list-dash-items list-widget-structure">
                            <div class="list-row bold">
                                <div class="list-col l-item">
                                    <i class="uil uil-edit-alt"></i>
                                </div>
                                <div class="list-col l-title">
                                    <input class="list-input" type="text" placeholder="عنوان وظیفه" v-model="activeTodoItem.title" @blur="saveTodoItem()">
                                </div>
                            </div>
                            <div class="list-row bold">
                                <div class="list-col l-item">
                                    <i class="uil uil-calendar-alt"></i>
                                </div>
                                <div class="list-col l-title">
                                    <date-picker v-model="activeTodoItem.date" placeholder="افزودن تاریخ" appendTo="body" inputClass="list-input Datepicker max-h no-border" :clearable="true" @change="saveTodoItem()"></date-picker>
                                </div>
                            </div>
                            <div class="list-row bold">
                                <div class="list-col l-item">
                                    <i class="uil uil-paperclip"></i>
                                </div>
                                <div class="list-col l-title position-relative">
                                    <span class="position-absolute">پیوست فایل</span>
                                    <input class="list-input select-file" type="file" ref="todo_attachments" @change="uploadAttachment($event.target.files)">
                                </div>
                            </div>
                            <div class="list-row atachments" v-if="activeTodoItem.attachments.length">
                                <div v-for="attachment in activeTodoItem.attachments" :key="attachment.id">
                                    <span class="uil uil-times remove-row" @click="removeAttachment(attachment.id)"></span>
                                    <span class="badge badge-secondary atachments-items pointer" @click="downloadAttachment(attachment.id)">
                                        {{ attachment.title }}
                                    </span>
                                </div>
                            </div>
                            <TextareaAutosize class="list-row-textarea min-h250" placeholder="افزودن یادداشت ..." v-model="activeTodoItem.note" :min-height="30" :max-height="350" @blur="saveTodoItem()" />
                        </div>
                    </div>
                </div>
                <div v-if="sidebarOptions == 'options'" class="sidebar-options">
                    <div class="sidebar-title-row">
                        <h3><i class="uil uil-setting"></i> تنظیمات وظایف روزانه</h3>
                        <i class="uil uil-times pointer" @click.prevent="showSidebar2()"></i>
                    </div>
                    <div class="list-widget list-dash-items list-widget-structure" v-if="editable && wrapper != 'dashboard'">
                        <div class="list-row no-filter hover bold">
                            <div class="checkbox-modal-list" style="padding: 10px 10px 13px !important;">
                                <input type="checkbox" class="custom-control-input todo-check-show" id="customControlValidation1" v-model="showChecked">
                                <label class="custom-control-label" for="customControlValidation1">نمایش موارد انجام شده</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="sidebarShow == true" @click.prevent="hideSidebar2()" class="calendar-sidebar-bg"></div>
        </slot>
    </div>
</div>
</template>

<style lang="scss">
.vpd-main {
    padding: 0 !important;

    label {
        display: none;
    }

    .vpd-input-group {
        padding: 0 !important;
        padding-right: 11px !important;

        .vpd-clear-btn {
            top: 4px;
        }
    }

    .Datepicker {
        padding: 0 !important;
    }
}

.widget-min-box-content {
    .l-title {
        display: flex;
    }

    .edit-todo {
        font-size: 14px;
        cursor: pointer;
        margin-right: -3px;
    }
}
</style>

<script>
// این کامپوننت اجزای وظایف روزانه در خود جای میدهد
import draggable from 'vuedraggable'
import VueTextareaAutosize from "vue-textarea-autosize";
Vue.use(VueTextareaAutosize);

export default {
    name: 'Todo',
    props: ['widget', 'data', 'editable', 'wrapper'],
    components: {
        draggable
    },
    data() {
        return {
            sidebarShow: false,
            sidebarOptions: 'list',
            searchQuery: '',
            todos: [],
            activeTodos: [],
            activeTodo: null,
            activeTodoItem: null,
            todoStarList: [],
            todoStarShow: false,
            newTodo: '',
            newTodoItem: '',
            todoFilterInput: '',
            todoListFilterInput: '',
            todoPanelOpen: false,
            showChecked: false,
            todoPanelIndex: null,
            responsive: {
                rightSection: true,
                leftSection: false,
                leftPanel: false,
            },
            widgetLoad: true,
            addEditLoading: false,
            addEditTodoLoading: false,
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length && (typeof oldValue == 'undefined' || !Object.keys(oldValue).length)) {
                // تزریق داده های ورود به کامپوننت
                this.loadData();
            }
        }, { immediate: true });

        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
    },
    computed: {
        // فیتلر سازی لیست های وظایف
        filteredList() {
            return this.todos.filter(todo => {
                return todo.title.toLowerCase().includes(this.searchQuery.toLowerCase())
            })
        },
    },
    methods: {
        // این تابع وظیفه تزریق داده های ورودی به کامپوننت را دارد	
        loadData() {
            this.todos = this.data.todos;

            if (this.todos.length) {
                this.activeTodo = this.todos[0];
            }
            this.widgetLoad = false;
        },
        // این متد جهت مشاهده و فیلتر سازی ستاره دار ها می باشد
        showStarsTodo() {
            this.todoStarList = [];
            var elm = [];
            for (let i = 0; i < this.todos.length; i++) {
                if (this.todos[i].items.length > 0) {
                    for (let j = 0; j < this.todos[i].items.length; j++) {
                        if (this.todos[i].items[j].star == true) {
                            elm.push(this.todos[i].items[j]);
                        }
                    }
                }
            }

            this.todoStarList = elm;
            this.todoStarShow = true;
            this.activeTodo = 0;
        },
        // این متد وظیفه ی حذف یک وظیفه از لیست وظیفه ها را دارد
        removeTodo(id) {
            this.$helpers.confirm('هشدار حذف!', {
                message: 'آیا از حذف این مورد اطمینان دارید؟',
            }).then((confirmed) => {
                if (confirmed) {
                    let index = this.$helpers.getIndex(this.todos, id);

                    this.todos.splice(index, 1);

                    let next_index = index - 1;
                    if (next_index < 0)
                        next_index = 0;

                    if (this.todos.length > 0) {
                        this.openTodo(this.todos[next_index].id);
                    } else {
                        this.activeTodo = null;
                        this.activeTodoItem = null;
                    }
                    // ارسال درخواست به وب سرویس	
                    this.$helpers.makeRequest('DELETE', '/todo/delete/' + id).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            // this.updateSource();
                            this.$swal.fire({
                                icon: "success",
                                title: "فهرست با موفقیت حذف شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در حذف فهرست رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
        // این متد وظیفه این را دارد پس از کلیک روی وظیفه مورد نظر آیتم های آن را لود میکند
        openTodo(id) {
            this.todoStarShow = false;

            let index = this.$helpers.getIndex(this.todos, id);

            this.activeTodo = this.todos[index];

            this.todoPanelOpen = false;

            this.sidebarShow = false;
        },
        // این متد وظیفه این را دارد که یک وظیفه جدید را اضافه میکند
        addTodo() {
            if (!this.newTodo) {
                this.$swal.fire({
                    icon: "error",
                    title: "لطفا عنوان فهرست را وارد نمایید",
                    showConfirmButton: !1,
                    timer: 2000
                });
            }
            if (!this.newTodo) return;
            this.addEditLoading = true;
            let id = 'id-' + new Date().getTime();

            let index = this.todos.push({
                id,
                title: this.newTodo,
                items: []
            });

            let api_data = {
                title: this.newTodo,
                items: null,
            };

            this.$helpers.makeRequest('POST', '/todo/save', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    let inserted_id = api_response.data.response.id;

                    this.todos.forEach((todo, index) => {
                        if (todo.id == id) {
                            todo.id = inserted_id;
                        }
                    });
                    this.$swal.fire({
                        icon: "success",
                        title: "فهرست با موفقیت ایجاد شد",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در ایجاد فهرست رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                }
                this.addEditLoading = false;
            });

            this.newTodo = '';
            this.openTodo(id);
        },
        // این متد برای ستاره دار کردن یکی از آیتم های وظیفه می باشد
        starTodoItem(id) {
            let index = this.$helpers.getIndex(this.activeTodo.items, id);

            this.activeTodo['items'][index]['star'] = !this.activeTodo['items'][index]['star'];

            this.saveTodoItem();
        },
        // این متد برای تیک دار کردن یکی از آیتم های وظیفه می باشد 
        checkTodoItem(id) {
            let index = this.$helpers.getIndex(this.activeTodo.items, id);

            this.activeTodo['items'][index]['checked'] = !this.activeTodo['items'][index]['checked'];

            this.saveTodoItem();
        },
        // این متد وظیفه اپلود فایل اتچ شده به آیتم مورد نظر از وظیفه انتخاب شده
        uploadAttachment(fileList) {
            let attachment = [];
            if (fileList.length) {
                attachment = fileList[0];
            } else {
                attachment = null;
            }

            if (attachment) {
                let api_data = {
                    attachment
                };

                this.$helpers.makeRequest('POST', '/todo/upload', api_data).then(api_response => {
                    this.$helpers.api_alert(api_response);

                    if (api_response.status == 200) {
                        let response = api_response.data.response;
                        this.activeTodoItem.attachments.push(response);
                        this.saveTodoItem('uploadAttachment');
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در بروزرسانی فایل پیوست رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                });
            }
        },
        // این متد برای حذف فایل آپلود شده
        removeAttachment(attach_id) {
            let api_data = {
                todo_id: this.activeTodo.id,
                todo_item_id: this.activeTodoItem.id,
                attach_id: attach_id,
                type: 'remove',
            };

            this.$helpers.makeRequest('GET', `/todo/download-or-remove`, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    let index = this.$helpers.getIndex(this.activeTodoItem.attachments, attach_id);
                    this.activeTodoItem.attachments.splice(index, 1);
                    this.saveTodoItem('removeAttachment');
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در حذف فایل پیوست رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                }
            });
        },
        // این متد جهت دانلود فایل آپلود شده می باشد
        downloadAttachment(attach_id) {
            let api_data = {
                todo_id: this.activeTodo.id,
                todo_item_id: this.activeTodoItem.id,
                attach_id: attach_id,
                type: 'download',
            };

            this.$helpers.makeRequest('GET', `/todo/download-or-remove`, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    window.open(api_response.data.link, '_blank');
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در دانلود فایل پیوست رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                }
            });
        },
        // این متد برای فیلتر کردن لیست وظایف و ستاره داره بودن آنها استفاده میشود
        filterTodo() {
            if (!this.todoStarShow) {
                this.activeTodo['items'].forEach((todoItem, index) => {
                    if (this.todoFilterInput.trim() == '') {
                        this.activeTodo['items'][index]['hide'] = false;
                    } else if (todoItem.title.indexOf(this.todoFilterInput.trim()) > -1) {
                        this.activeTodo['items'][index]['hide'] = false;
                    } else {
                        this.activeTodo['items'][index]['hide'] = true;
                    }
                });
            } else {
                this.todoStarList.forEach((todoItem, index) => {
                    if (this.todoFilterInput.trim() == '') {
                        this.todoStarList[index]['hide'] = false;
                    } else if (todoItem.title.indexOf(this.todoFilterInput.trim()) > -1) {
                        this.todoStarList[index]['hide'] = false;
                    } else {
                        this.todoStarList[index]['hide'] = true;
                    }
                });
            }
        },
        triggerTodoPanel(id) {
            let index = this.$helpers.getIndex(this.activeTodo.items, id);

            if (index != this.todoPanelIndex) {
                this.todoPanelOpen = true;
            } else {
                this.todoPanelOpen = !this.todoPanelOpen;
            }

            this.todoPanelIndex = index;
            this.activeTodoItem = this.activeTodo['items'][index];
            this.showSidebar('edit');
        },
        // این متد برای حذف آیتم های لیست وظایف استفاده میشود
        removeTodoItem(id) {
            let index = this.$helpers.getIndex(this.activeTodo.items, id);

            this.todoPanelIndex = null;
            this.todoPanelOpen = false;
            this.activeTodo.items.splice(index, 1);

            this.saveTodoItem('removeTodo');
            this.sidebarShow = false;
            this.sidebarOptions = false;
        },
        // این متد وظیفه هندل کردن افزودن آیتم به لیست وظایف را دارا می باشد
        addTodoItem() {
            if (!this.newTodoItem) {
                this.$swal.fire({
                    icon: "error",
                    title: "لطفا عنوان وظیفه را وارد نمایید",
                    showConfirmButton: !1,
                    timer: 2000
                });
            }
            if (!this.newTodoItem) {
                return;
            }

            if (this.newTodoItem) {
                this.activeTodo.items.push({
                    id: 'id-' + new Date().getTime(),
                    title: this.newTodoItem,
                    note: '',
                    date: null,
                    star: false,
                    attachments: [],
                    checked: false,
                    hide: false
                });

                this.newTodoItem = '';
                this.saveTodoItem('addTodo');
            }
        },
        // این متد درخواست ذخیره سازی آیتم جدید لیست وظایف را به وب سرویس ارسال میکند
        saveTodoItem(action = null) {
            let api_data = {
                items: JSON.stringify(this.$helpers.unbindObject(this.activeTodo.items)),
            };

            if (action == 'addTodo') {
                this.addEditTodoLoading = true;
            }

            this.$helpers.makeRequest('POST', '/todo/item-save/' + this.activeTodo.id, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (action == 'addList') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "فهرست با موفقیت ایجاد شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در ایجاد فهرست رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else if (action == 'removeList') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "فهرست با موفقیت حذف شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در حذف فهرست رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else if (action == 'updateList') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "فهرست با موفقیت بروزرسانی شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در بروزرسانی فهرست رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else if (action == 'addTodo') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "وظیفه با موفقیت ایجاد شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                        this.addEditTodoLoading = false;
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در ایجاد وظیفه رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                        this.addEditTodoLoading = false;
                    }
                } else if (action == 'removeTodo') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "وظیفه با موفقیت حذف شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در حذف وظیفه رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else if (action == 'updateTodo') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "وظیفه با موفقیت بروزرسانی شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در بروزرسانی وظیفه رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else if (action == 'addAttachment') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "فایل پیوست با موفقیت افزوده شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در افزوده فایل پیوست رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else if (action == 'removeAttachment') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "فایل پیوست با موفقیت حذف شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در حذف فایل پیوست رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                } else if (action == 'updateAttachment') {
                    if (api_response.status == 200) {
                        this.$swal.fire({
                            icon: "success",
                            title: "فایل پیوست با موفقیت بروزرسانی شد",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    } else {
                        this.$swal.fire({
                            icon: "error",
                            title: "مشکلی در بروزرسانی فایل پیوست رخ داده است",
                            showConfirmButton: !1,
                            timer: 2000
                        });
                    }
                }
            });
        },
        // این متد جهت هندل سورت کردن و ترتیب قرار گیری ایتم های لیست وظایف استفاده میشود
        saveTodosSort() {
            let sort = [];
            this.todos.forEach(todo => {
                sort.push(todo.id);
            });

            let api_data = {
                sort: JSON.stringify(sort),
            };

            this.$helpers.makeRequest('POST', '/todo/sort', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                if (api_response.status == 200) {
                    Vue.prototype.$toast.success({
                        title: 'ذخیره',
                        message: 'مرتب سازی با موفقیت ذخیره شد',
                    });
                } else {
                    Vue.prototype.$toast.warn({
                        title: 'ذخیره',
                        message: 'مشکلی در مرتب سازی رخ داده است',
                    });
                }
            });
        },
        // این متد وظیفه هندل کردن مودال تنظیمات وظایف در ویجت داشبورد است
        open_settings_modal() {
            let callbackEvent = this.$router.currentRoute.name + '_save_settings_' + this.widget.id + '_' + new Date().getTime();

            let modal_data = {
                modal: 'widget_settings_modal',
                callbackEvent,
                component: 'Settings/Todo.vue',
                config: {
                    data: {
                        title: this.widget.title,
                        showChecked: this.widget.data.showChecked,
                    }
                }
            };

            modal_data = this.$helpers.unbindObject(modal_data);

            this.$root.$emit('open_modal', modal_data);
            // کال بک فانکشن
            this.$root.$on(callbackEvent, (settings) => {
                this.widget.data.showChecked = settings.showChecked;
                this.widget.title = settings.title;

                let widget = this.$helpers.unbindObject(this.widget);

                this.$helpers.saveWidget(widget);
            });
        },
        // این متد برای ویرایش عنوان وظیفه استفاده میشود // هندل کردن مودال و کال بک فاکنش
        open_todo_edit_modal(id = 0) {
            if (id > 0) {
                let index = this.$helpers.getIndex(this.todos, id);
                this.activeTodo = this.todos[index];
            }

            let callbackEvent = this.$router.currentRoute.name + '_EditTodos_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'TodoEdit.vue',
                config: {
                    title: 'ویرایش فهرست',
                    icon: 'uil uil-edit-alt with-icon',
                    smallModal: true,
                    data: {
                        todo: this.$helpers.unbindObject(this.activeTodo),
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'editTodo') {
                    this.activeTodo.title = response.data.todo.title;

                    let api_data = {
                        title: this.activeTodo.title,
                    };

                    this.$helpers.makeRequest('POST', '/todo/edit/' + this.activeTodo.id, api_data).then(api_response => {
                        this.$helpers.api_alert(api_response);

                        if (api_response.status == 200) {
                            // this.updateSource();
                            this.$swal.fire({
                                icon: "success",
                                title: "فهرست با موفقیت بروزرسانی شد",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        } else {
                            this.$swal.fire({
                                icon: "error",
                                title: "مشکلی در بروزرسانی فهرست رخ داده است",
                                showConfirmButton: !1,
                                timer: 2000
                            });
                        }
                    });
                }
            });
        },
        makeResponsive(mode) {
            if (mode == 1) {
                this.responsive.rightSection = false;
                this.responsive.leftSection = true;
                this.responsive.leftPanel = false;
            } else if (mode == 2) {
                this.responsive.rightSection = true;
                this.responsive.leftSection = false;
                this.responsive.leftPanel = false;
            } else if (mode == 3) {
                this.responsive.rightSection = false;
                this.responsive.leftSection = false;
                this.responsive.leftPanel = true;
            }
        },
        showSidebar(e) {
            if (this.sidebarOptions == e) {
                this.sidebarShow = false;
                this.sidebarOptions = null;
            } else {
                this.sidebarOptions = e;
                this.sidebarShow = true;
            }
        },
        showSidebar2(e) {
            if (this.sidebarOptions == e) {
                this.sidebarShow = true;
                this.sidebarOptions = null;
            } else {
                this.sidebarOptions = e;
                this.sidebarShow = false;
            }
        },
        hideSidebar() {
            this.sidebarShow = false;
        },
        hideSidebar2() {
            this.sidebarShow = false;
            this.sidebarOptions = null;
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Todo.vue',
                title: this.widget.title,
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
